export enum CartoCatalogErrorCode {
  // General errors
  InvalidRequestBody = 1001,
  ValidationError = 1002,
  InternalServerError = 1099,

  // Connection creation errors
  ConnectionTimeout = 2001,
  ProviderCredentialsError = 2002,
  NeedRepairError = 2104,

  UnsupportedPostgreSQLVersion = 2051,
  UnsupportedPostGISVersion = 2052,
  PostGISExtensionNotFound = 2053,
  RequireViewerCredentials = 2104,

  // Connection check write permission errors
  TableWillBeOverwritten = 2201,
  TableAlreadyExists = 2202,
  LocationNotFound = 2203,
  UnauthorizedTableLocation = 2204,
  UnknownTableAccessError = 2209,

  FrontendUnknown = 9999 // TODO: while we don't migrate errors for import we need to have a unknown error code
}

export interface CartoApiError {
  status: number
  code: CartoCatalogErrorCode
  error: string
  title: string
  severity: 'error' | 'warning'
  detail?: string
  frontendMetadata?: Record<string, string> // To inject some metadata directly from frontend
}
