import { getLocalStorageItem, setLocalStorageItem } from 'app/api/localStorage'
import { FeatureFlag, FeatureFlagOverrides } from 'app/types'
import { setFeatureFlagOverrides } from 'features/common/state/commonSlice'
import { getStore } from './storeRegistry'

/**
 * Override feature flag.
 *
 * By default, Cloud Native feature flags are loaded using LD (Launch Darkly) API using account it.
 * Use this function to override feature flags for _this_ browser client.
 *
 * Override is saved in local storage.
 *
 * This function is also available as `window.__cartoInternalModifier('overrideFeatureFlag', flag, state)`.
 */
export function overrideFeatureFlag(flag: FeatureFlag, value: boolean | string | undefined) {
  const currentOverrides = getFeatureFlagOverrides() || {}
  const newOverrides = { ...currentOverrides }
  if (value === undefined) {
    delete newOverrides[flag]
  } else {
    newOverrides[flag] = value
  }
  saveFeatureFlagOverrides(newOverrides)
  const store = getStore()
  if (store) {
    store.dispatch(setFeatureFlagOverrides(newOverrides))
  }
}

export const FEATURE_FLAG_OVERRIDES_KEY = 'featureFlagOverrides'

export function getFeatureFlagOverrides(): FeatureFlagOverrides | undefined {
  const overrides = getLocalStorageItem(FEATURE_FLAG_OVERRIDES_KEY) || {}

  return { ...overrides, [FeatureFlag.SnowflakeRowLevelSecurity]: true }
}

export function saveFeatureFlagOverrides(value: FeatureFlagOverrides) {
  setLocalStorageItem(FEATURE_FLAG_OVERRIDES_KEY, value)
}
