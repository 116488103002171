import { FEATURE_FLAG_OVERRIDES_KEY } from 'app/state/featureFlags'
import { DEFAULT_CONNECTION_ITEM } from 'features/connections/types'
import { REQUEST_SUBS } from 'features/data-catalog/utils/subscriptionRequestsUtils'
import { CARTO_EXPORT_TASKS, CARTO_IGNORED_PROCESSING_JOBS } from 'features/jobs/types'

const LOCAL_STORAGE_PREFIX = 'carto-workspace-www'
const LOCAL_STORAGE_VERSION_ITEM = 'VERSION'
export const LOCAL_STORAGE_VERSION = 1

export const localStorageSupported = () => {
  try {
    window.localStorage
  } catch (e) {
    return false
  }
  return true
}

export const validateLocalStorage = () => {
  const currentVersion = getLocalStorageItem(LOCAL_STORAGE_VERSION_ITEM)
  if (!currentVersion || currentVersion !== LOCAL_STORAGE_VERSION) {
    setLocalStorageItem(LOCAL_STORAGE_VERSION_ITEM, LOCAL_STORAGE_VERSION)
    clearLocalStorage()
  }
}

export const getLocalStorageItem = (item: string) => {
  try {
    const itemValue = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}-${item}`)
    return itemValue ? JSON.parse(itemValue) : null
  } catch (e) {
    return null
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setLocalStorageItem = (item: string, value: any) => {
  try {
    localStorage.setItem(`${LOCAL_STORAGE_PREFIX}-${item}`, JSON.stringify(value))
  } catch (e) {
    return
  }
}

export const removeLocalStorageItem = (item: string) => {
  try {
    localStorage.removeItem(`${LOCAL_STORAGE_PREFIX}-${item}`)
  } catch (e) {
    return
  }
}

const clearLocalStorage = () => {
  removeLocalStorageItem(REQUEST_SUBS)
  removeLocalStorageItem(CARTO_IGNORED_PROCESSING_JOBS)
  removeLocalStorageItem(DEFAULT_CONNECTION_ITEM)
  removeLocalStorageItem(FEATURE_FLAG_OVERRIDES_KEY)
  removeLocalStorageItem(CARTO_EXPORT_TASKS)
}
