import { ApiError, CartoApiError, CommonPrivacy, RequestStatus } from 'features/common'
import { Group } from 'features/groups'
import { ExternalIdp } from 'features/integrations/types'

export const DEFAULT_CONNECTION_ITEM = 'defaultConnection'

export const OAUTH_TOKEN_CONFIG_TYPE = 'oauth_token'

export enum BigQueryConfigType {
  ServiceAccount = 'service_account',
  OAuthToken = 'oauth_token',
  WorkloadIdentity = 'workload_identity'
}

export enum PostgresConfigType {
  UserPassword = 'user_password'
}

export enum RedshiftConfigType {
  UserPassword = 'user_password'
}

export enum SnowflakeConfigType {
  SnowflakeAccount = 'snowflake_account',
  OAuthToken = 'oauth_token'
}

export enum DatabricksConfigType {
  SparkOdbc = 'spark_odbc',
  Rest = 'rest'
}

export enum DatabricksClusterState {
  Pending = 'PENDING',
  Running = 'RUNNING',
  Restarting = 'RESTARTING',
  Resizing = 'RESIZING',
  Terminating = 'TERMINATING',
  Terminated = 'TERMINATED',
  Error = 'ERROR',
  Unknown = 'UNKNOWN'
}

export enum ProviderType {
  BIGQUERY = 'bigquery',
  POSTGRES = 'postgres',
  SNOWFLAKE = 'snowflake',
  REDSHIFT = 'redshift',
  DATABRICKS = 'databricks',
  DATABRICKS_REST = 'databricksRest',
  CARTO = 'carto',
  CARTO_DW = 'carto_dw',
  FABRIC = 'fabric'
}

export enum ConnectionType {
  BigQueryServiceAccount = 'bigqueryServiceAccount',
  BigQueryOAuth = 'bigQueryOAuth',
  BigQueryWorkloadIdentity = 'bigQueryWorkloadIdentity',
  Postgres = 'postgresPassword',
  Redshift = 'redshiftPassword',
  SnowflakeAccount = 'snowflakeAccount',
  SnowflakeOAuth = 'snowflakeOAuth',
  Databricks = 'databricks',
  DatabricksRest = 'databricksRest'
}

export enum ViewerCredentialsConditions {
  SSO = 'sso'
}

export interface ProviderConfig {
  label: string
  placeholder?: string
  optional?: boolean
  optionalOnEdit?: boolean
  type?: string
  showInDetails?: boolean
  defaultValue?: string | number
}

export interface Provider {
  label: string
  labelLong?: string
  docs?: string
  hasSpatialExtension: boolean
  icon: JSX.Element
  contactUs?: boolean
  config?: { type: string; credentials?: Record<string, ProviderConfig> }
}

export type ConnectionPrivacy = CommonPrivacy
export const ConnectionPrivacy = {
  Private: CommonPrivacy.Private,
  Shared: CommonPrivacy.Shared
}

export interface WorkflowTempLocation {
  name: string
  id: string
  fqn: string
  region?: string
}

export type Connection<T = ConnectionParams> = {
  id: string
  name: string
  provider_id: ProviderType
  mapcount?: number
  config: {
    type: string
    billing_project?: string
    credentials: T
    dataTransferVersionInfo?: string
  }
  workflowsTempLocations?: WorkflowTempLocation[]
  account_id?: string
  user_id?: string
  shared?: boolean
  editable?: boolean
  updated_at?: string
  ownerPicture?: string
  ownerEmail?: string
  ownerIsCartoUser?: boolean
  carto_dw?: boolean
  spatial_extension?: boolean
  privacy?: CommonPrivacy
  groups?: Group[]
  invalidOAuth?: boolean
  created_at?: string
  atVersion?: string | null
  atDatabase?: string | null
  atLocation?: string | null
  doLocation: string
  viewerCredentials?: boolean | undefined
  viewerCredentialsAttached?: boolean | undefined
  external_idp_id?: string
  importsAvailable?: boolean
  exportToBucketAvailable?: boolean
  maxConcurrentQueries?: number | null
  queryTimeoutS?: number | null
  providerRuntimeMetadata?: {
    clusterState: DatabricksClusterState
    warehouseState: string
    clusterStateMessage: string
  }

  // frontend only: used by ConnectionList & AnalyticsToolboxStatus to show update status
  atLatestVersion?: string
}

export type PatchConnectionParams = Pick<Connection, 'id'> &
  Partial<
    Pick<
      Connection,
      | 'spatial_extension'
      | 'config'
      | 'privacy'
      | 'groups'
      | 'workflowsTempLocations'
      | 'viewerCredentials'
      | 'doLocation'
      | 'atLocation'
      | 'maxConcurrentQueries'
      | 'queryTimeoutS'
    >
  >

export interface ConnectionParams {
  password: string
  database: string
  role?: string
  ssl?: boolean
  client_email?: string
  externalIdp?: ExternalIdp
  client_picture?: string
  state?: string
  groups?: Group[]
  username?: string
}

export interface PostgresParams extends ConnectionParams {
  user: string
  host: string
  port: number
}

export interface RedshiftParams extends ConnectionParams {
  user: string
  host: string
  port: number
}

export interface SnowflakeParams extends ConnectionParams {
  username: string
  account: string
  warehouse: string
}

export interface DatabricksParams extends ConnectionParams {
  server: string
  httpPath: string
  port: number
}

export interface DatabricksRestParams extends ConnectionParams {
  host: string
  token: string
  catalog: string
  warehouseId: string
  clusterId: string
}

export interface BigQueryServiceAccountCredentials {
  type: 'service_account'
  project_id: string
}

export interface BigQueryConnectionProject {
  id: string
  name: string
  _links: { datasets: string }
}

export interface BigQueryConnectionDataset {
  id: string
  name: string
  _links: Record<string, string>
}

export interface ServiceAccountFile {
  name: string
  content?: string
}

export interface BigQueryConnectionCreator {
  name?: string
  file?: ServiceAccountFile
  serviceAccount?: string
  projects?: BigQueryConnectionProject[]
  projectsError?: CartoApiError
  projectsStatus?: RequestStatus
}

export interface OAuthConsentUrl {
  url: string
  provider: ProviderType
}

export type OAuthState = OAuthBQState | OAuthSnowflakeState

export interface OAuthBQState {
  sub: string
  name: string
  given_name: string
  family_name: string
  picture: string
  email: string
  email_verified: boolean
  locale: string
  hd: string
  state: string
}

export interface OAuthSnowflakeState {
  state: string
  userId: string
  email?: string
}

export interface OauthConsentRequest {
  provider: ProviderType
  params?: Record<string, string | undefined>
  connectionId?: string
}

export interface ConnectionOAuth {
  consentUrl?: string
  oauthState?: OAuthState
  connectionId: string
}

export interface Dataset {
  accountId: string
  connectionName: string
  connectionProviderId: ProviderType
  fqn: string
  type: string
  name: string
}

export type AttachConnectionCredentialsParams = {
  connectionId: string
  config: { type: string; credentials: ConnectionParams }
}

export interface CredentialsMutationResponse {
  connectionId: string
  error?: ApiError | undefined
}
