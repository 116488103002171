import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit'
import { ApiError, CartoApiError, PaginatedResponse, RequestStatus } from 'features/common'
import { Map } from 'features/maps/types'
import {
  BigQueryConnectionCreator,
  BigQueryConnectionDataset,
  BigQueryConnectionProject,
  Connection,
  DEFAULT_CONNECTION_ITEM,
  ProviderType,
  ServiceAccountFile,
  Dataset,
  OauthConsentRequest
} from '../types'

export const name = 'connections'
export const CONNECTION_FETCH_REQUEST = `${name}/fetch_connections_request`
export const CONNECTION_FETCH_ONE_REQUEST = `${name}/fetch_one_connection_request`
export const CONNECTION_CREATE_REQUEST = `${name}/create_connections_request`
export const CONNECTION_DELETE_REQUEST = `${name}/delete_connections_request`
export const BQ_CONNECTION_PROJECTS_CREATOR_FETCH_REQUEST = `${name}/fetch_bq_connections_creator_projects_request`
export const BQ_CONNECTION_PROJECTS_FETCH_REQUEST = `${name}/fetch_bq_connections_projects_request`
export const BQ_CONNECTION_DATASETS_FETCH_REQUEST = `${name}/fetch_bq_connections_datasets_request`
export const CONNECTION_RELATED_MAPS = `${name}/fetch_connection_related_maps`
export const CONNECTIONS_PROVIDER_SELECTED_REQUEST = `${name}/connection_provider_selected_request`
export const OAUTH_CONSENT_REQUEST = `${name}/fetch_oauth_request`
export const OAUTH_CODE_REQUEST = `${name}/fetch_oauth_code_request`
export const OAUTH_BILLING_PROJECTS_REQUEST = `${name}/fetch_oauth_billing_projects_request`
export const UPDATE_ANALYTICS_TOOLBOX_REQUEST = `${name}/update_analytics_toolbox_request`
export const RECENT_RESOURCES_FETCH_REQUEST = `${name}/fetch_recent_resources_request`

import { User } from 'features/users'
import { RootState } from 'app/state/store'
import { setLocalStorageItem } from 'app/api/localStorage'
import { createResource } from 'app/state/resource'

export interface ConnectionsState {
  myConnections: Array<Connection>
  myConnectionsStatus: RequestStatus
  myConnectionsError?: ApiError | null
  currentConnection?: Connection
  currentConnectionStatus: RequestStatus
  currentConnectionError?: ApiError | null
  createConnectionStatus: RequestStatus
  createConnectionError?: CartoApiError | null
  deleteConnectionStatus: RequestStatus
  deleteConnectionError?: CartoApiError | null
  changeConnectionPrivacyStatus: RequestStatus
  bigQueryConnectionProjects: Array<BigQueryConnectionProject>
  bigQueryConnectionProjectsStatus: RequestStatus
  bigQueryConnectionProjectsError?: ApiError | null
  bigQueryConnectionDatasets: Array<BigQueryConnectionDataset>
  bigQueryConnectionDatasetsStatus: RequestStatus
  bigQueryConnectionDatasetsError?: ApiError | null
  bigQueryConnectionCreator: BigQueryConnectionCreator
  connectionRelatedMaps?: PaginatedResponse<Map>
  connectionRelatedMapsStatus: RequestStatus
  defaultConnection?: Connection
  oauthBillingProjects: Array<BigQueryConnectionProject>
  oauthBillingProjectsStatus: RequestStatus
  oauthBillingProjectsError?: CartoApiError | null
  updatingConnectionsToolbox: Array<string>
  recentResources: Array<Dataset>
  recentResourcesStatus: RequestStatus
  recentResourcesError?: ApiError | null
}

const initialState: ConnectionsState = {
  myConnections: [],
  myConnectionsStatus: RequestStatus.Idle,
  currentConnectionStatus: RequestStatus.Idle,
  createConnectionStatus: RequestStatus.Idle,
  deleteConnectionStatus: RequestStatus.Idle,
  changeConnectionPrivacyStatus: RequestStatus.Idle,
  bigQueryConnectionProjects: [],
  bigQueryConnectionProjectsStatus: RequestStatus.Idle,
  oauthBillingProjects: [],
  oauthBillingProjectsStatus: RequestStatus.Idle,
  bigQueryConnectionDatasets: [],
  bigQueryConnectionDatasetsStatus: RequestStatus.Idle,
  bigQueryConnectionCreator: {},
  connectionRelatedMapsStatus: RequestStatus.Idle,
  updatingConnectionsToolbox: [],
  recentResources: [],
  recentResourcesStatus: RequestStatus.Idle
}

const asyncActions = {
  connectionsRequest: createAction(CONNECTION_FETCH_REQUEST),
  oauthConsentRequest: createAction<OauthConsentRequest>(OAUTH_CONSENT_REQUEST),
  updateAnalyticsToolboxRequest: createAction<Pick<Connection, 'id' | 'atLocation'>>(UPDATE_ANALYTICS_TOOLBOX_REQUEST),
  oauthBillingProjectsRequest: createAction<{ state: string; client_email: string }>(OAUTH_BILLING_PROJECTS_REQUEST),
  oauthCodeRequest: createAction<{ provider: ProviderType; params: string; connectionId?: string }>(OAUTH_CODE_REQUEST),
  trackConnectionProviderSelected: createAction<{ providerId: string; user: User }>(
    CONNECTIONS_PROVIDER_SELECTED_REQUEST
  ),
  fetchBQConnectionCreatorProjectsRequest: createAction<string>(BQ_CONNECTION_PROJECTS_CREATOR_FETCH_REQUEST),
  createConnectionRequest: createAction<Connection>(CONNECTION_CREATE_REQUEST),
  fetchConnection: createAction<string>(CONNECTION_FETCH_ONE_REQUEST),
  fetchConnectionMapsRelated: createAction<string>(CONNECTION_RELATED_MAPS),
  removeConnection: (
    id: string
  ): {
    type: string
    payload: string
  } => ({ type: CONNECTION_DELETE_REQUEST, payload: id }),
  fetchBQConnectionProjectsRequest: (
    id: string
  ): {
    type: string
    payload: string
  } => ({ type: BQ_CONNECTION_PROJECTS_FETCH_REQUEST, payload: id }),
  fetchBQConnectionDatasetsRequest: (payload: {
    connectionId: string
    projectId: string
  }): {
    type: string
    payload: { connectionId: string; projectId: string }
  } => ({ type: BQ_CONNECTION_DATASETS_FETCH_REQUEST, payload }),
  fetchRecentResourcesRequest: createAction<number>(RECENT_RESOURCES_FETCH_REQUEST)
}

const reducers = {
  setMyConnectionsStatus: (state: ConnectionsState, action: PayloadAction<RequestStatus>) => {
    state.myConnectionsStatus = action.payload
  },
  setMyConnectionsError: (state: ConnectionsState, action: PayloadAction<ApiError>) => {
    state.myConnectionsError = action.payload
  },
  setCurrentConnection: (state: ConnectionsState, action: PayloadAction<Connection | undefined>) => {
    state.currentConnection = action.payload
  },
  setCurrentConnectionStatus: (state: ConnectionsState, action: PayloadAction<RequestStatus>) => {
    state.currentConnectionStatus = action.payload
  },
  setCurrentConnectionError: (state: ConnectionsState, action: PayloadAction<ApiError>) => {
    state.currentConnectionError = action.payload
  },
  setDeleteConnectionStatus: (state: ConnectionsState, action: PayloadAction<RequestStatus>) => {
    state.deleteConnectionStatus = action.payload
  },
  setDeleteConnectionError: (state: ConnectionsState, action: PayloadAction<CartoApiError | null>) => {
    state.deleteConnectionError = action.payload
  },
  setCreateConnectionStatus: (state: ConnectionsState, action: PayloadAction<RequestStatus>) => {
    state.createConnectionStatus = action.payload
  },
  setCreateConnectionError: (state: ConnectionsState, action: PayloadAction<CartoApiError | null>) => {
    state.createConnectionError = action.payload
  },
  addConnections: (state: ConnectionsState, action: PayloadAction<Array<Connection>>) => {
    state.myConnections = action.payload
  },
  setBigQueryConnectionProjects: (state: ConnectionsState, action: PayloadAction<Array<BigQueryConnectionProject>>) => {
    state.bigQueryConnectionProjects = action.payload
  },
  setBigQueryConnectionProjectsStatus: (state: ConnectionsState, action: PayloadAction<RequestStatus>) => {
    state.bigQueryConnectionProjectsStatus = action.payload
  },
  setBigQueryConnectionProjectsError: (state: ConnectionsState, action: PayloadAction<ApiError>) => {
    state.bigQueryConnectionProjectsError = action.payload
  },
  setBigQueryConnectionDatasets: (state: ConnectionsState, action: PayloadAction<Array<BigQueryConnectionDataset>>) => {
    state.bigQueryConnectionDatasets = action.payload
  },
  setBigQueryConnectionDatasetsStatus: (state: ConnectionsState, action: PayloadAction<RequestStatus>) => {
    state.bigQueryConnectionDatasetsStatus = action.payload
  },
  setBigQueryConnectionDatasetsError: (state: ConnectionsState, action: PayloadAction<ApiError>) => {
    state.bigQueryConnectionDatasetsError = action.payload
  },
  setBigqueryConnectionCreator: (state: ConnectionsState, action: PayloadAction<BigQueryConnectionCreator>) => {
    state.bigQueryConnectionCreator = action.payload
  },
  setBigqueryConnectionCreatorName: (state: ConnectionsState, action: PayloadAction<string>) => {
    state.bigQueryConnectionCreator.name = action.payload
  },
  setBigqueryConnectionCreatorProjects: (
    state: ConnectionsState,
    action: PayloadAction<Array<BigQueryConnectionProject> | undefined>
  ) => {
    state.bigQueryConnectionCreator.projects = action.payload
  },
  setBigqueryConnectionCreatorFile: (
    state: ConnectionsState,
    action: PayloadAction<ServiceAccountFile | undefined>
  ) => {
    state.bigQueryConnectionCreator.file = action.payload
  },
  setBigqueryConnectionCreatorServiceAccount: (state: ConnectionsState, action: PayloadAction<string>) => {
    state.bigQueryConnectionCreator.serviceAccount = action.payload
  },
  setBigqueryConnectionCreatorProjectsError: (
    state: ConnectionsState,
    action: PayloadAction<CartoApiError | undefined>
  ) => {
    state.bigQueryConnectionCreator.projectsError = action.payload
  },
  setBigqueryConnectionCreatorProjectsStatus: (state: ConnectionsState, action: PayloadAction<RequestStatus>) => {
    state.bigQueryConnectionCreator.projectsStatus = action.payload
  },
  cleanProjects: (state: ConnectionsState) => {
    state.bigQueryConnectionProjects = []
  },
  cleanDatasets: (state: ConnectionsState) => {
    state.bigQueryConnectionDatasets = []
  },
  setConnectionRelatedMaps: (state: ConnectionsState, action: PayloadAction<PaginatedResponse<Map> | undefined>) => {
    state.connectionRelatedMaps = action.payload
  },
  setConnectionRelatedMapsStatus: (state: ConnectionsState, action: PayloadAction<RequestStatus>) => {
    state.connectionRelatedMapsStatus = action.payload
  },
  setDefaultConnection: (state: ConnectionsState, action: PayloadAction<Connection | undefined>) => {
    setLocalStorageItem(DEFAULT_CONNECTION_ITEM, action.payload)
    state.defaultConnection = action.payload
  },
  setOAuthBillingProjects: (state: ConnectionsState, { payload }: PayloadAction<Array<BigQueryConnectionProject>>) => {
    state.oauthBillingProjects = payload
  },
  setOAuthBillingProjectsStatus: (state: ConnectionsState, action: PayloadAction<RequestStatus>) => {
    state.oauthBillingProjectsStatus = action.payload
  },
  addToUpdatingConnectionsToolbox: (state: ConnectionsState, action: PayloadAction<string>) => {
    state.updatingConnectionsToolbox.push(action.payload)
  },
  deleteFromUpdatingConnectionsToolbox: (state: ConnectionsState, action: PayloadAction<string>) => {
    state.updatingConnectionsToolbox = state.updatingConnectionsToolbox.filter(
      (connectionId) => connectionId !== action.payload
    )
  },
  setOAuthBillingProjectsError: (state: ConnectionsState, action: PayloadAction<CartoApiError | null>) => {
    state.oauthBillingProjectsError = action.payload
  },
  updateConnectionFromList: (state: ConnectionsState, action: PayloadAction<Partial<Connection>>) => {
    state.myConnections = state.myConnections.map((connection) => {
      if (connection.id === action.payload.id) {
        return {
          ...connection,
          ...action.payload,
          groups: action.payload.groups
        }
      } else {
        return connection
      }
    })
    if (state.currentConnection && state.currentConnection.id === action.payload.id) {
      state.currentConnection = { ...state.currentConnection, ...action.payload, groups: action.payload.groups }
    }
  },
  setRecentResources: (state: ConnectionsState, action: PayloadAction<Dataset[]>) => {
    state.recentResources = action.payload
  },
  setRecentResourcesStatus: (state: ConnectionsState, action: PayloadAction<RequestStatus>) => {
    state.recentResourcesStatus = action.payload
  },
  setRecentResourcesError: (state: ConnectionsState, action: PayloadAction<ApiError>) => {
    state.recentResourcesError = action.payload
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getConnectionByNameAndProvider = (
  state: RootState,
  { connection, provider }: { connection: Connection['name']; provider: Connection['provider_id'] }
) => {
  return state.connections.myConnections.find((c) => c.name === connection && c.provider_id === provider)
}

export const getConnectionByName = (state: RootState, { connection }: { connection: Connection['name'] }) => {
  return state.connections.myConnections.find((c) => c.name === connection)
}

export const getConnectionById = (state: RootState, { connection }: { connection: Connection['id'] }) => {
  return state.connections.myConnections.find((c) => c.id === connection)
}

export const hasAnyBQConnection = (state: RootState) => {
  return state.connections.myConnections.some((c) => c.provider_id === ProviderType.BIGQUERY && !c.carto_dw)
}

export const getConnectionsByProvider = (state: RootState, { provider }: { provider: ProviderType }) => {
  return state.connections.myConnections.filter((c) => c.provider_id === provider)
}

export const getConnectionsByProviders = (state: RootState, providers: ProviderType[]) => {
  return state.connections.myConnections.filter((c) => providers.includes(c.provider_id))
}

export const getCartoDWConnection = (state: RootState) => {
  return state.connections.myConnections.find((c) => c.carto_dw)
}

export const myConnectionsResource = createResource({
  get: (state) => state.connections.myConnections,
  status: (state) => state.connections.myConnectionsStatus,
  error: (state) => state.connections.myConnectionsError,
  createAction: () => asyncActions.connectionsRequest()
})

export const connectionsSlice = createSlice({
  name,
  initialState,
  reducers
})

export const {
  addConnections,
  setMyConnectionsStatus,
  setMyConnectionsError,
  setCurrentConnection,
  setCurrentConnectionStatus,
  setCurrentConnectionError,
  setCreateConnectionStatus,
  setCreateConnectionError,
  setDeleteConnectionStatus,
  setDeleteConnectionError,
  setBigQueryConnectionProjects,
  setBigQueryConnectionProjectsStatus,
  setBigQueryConnectionProjectsError,
  setBigQueryConnectionDatasets,
  setBigQueryConnectionDatasetsStatus,
  setBigQueryConnectionDatasetsError,
  setBigqueryConnectionCreator,
  setBigqueryConnectionCreatorName,
  setBigqueryConnectionCreatorProjects,
  setBigqueryConnectionCreatorProjectsError,
  setBigqueryConnectionCreatorProjectsStatus,
  setBigqueryConnectionCreatorServiceAccount,
  setBigqueryConnectionCreatorFile,
  setConnectionRelatedMaps,
  setConnectionRelatedMapsStatus,
  setDefaultConnection,
  cleanProjects,
  cleanDatasets,
  setOAuthBillingProjects,
  setOAuthBillingProjectsStatus,
  setOAuthBillingProjectsError,
  addToUpdatingConnectionsToolbox,
  deleteFromUpdatingConnectionsToolbox,
  updateConnectionFromList,
  setRecentResources,
  setRecentResourcesStatus,
  setRecentResourcesError
} = connectionsSlice.actions
export const {
  connectionsRequest,
  createConnectionRequest,
  fetchConnection,
  removeConnection,
  fetchBQConnectionProjectsRequest,
  fetchBQConnectionDatasetsRequest,
  oauthConsentRequest,
  oauthCodeRequest,
  oauthBillingProjectsRequest,
  fetchBQConnectionCreatorProjectsRequest,
  fetchConnectionMapsRelated,
  trackConnectionProviderSelected,
  updateAnalyticsToolboxRequest,
  fetchRecentResourcesRequest
} = asyncActions

export default connectionsSlice.reducer
