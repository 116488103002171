import { getLocalStorageItem, setLocalStorageItem } from 'app/api/localStorage'

export const REQUEST_SUBS = 'CARTO_REQUEST_SUBSCRIPTION_DONE'

export function checkIfDatasetHasBeenRequested(datasetId: string) {
  const subscriptionsRequested: string[] = getLocalStorageItem(REQUEST_SUBS) || []
  return subscriptionsRequested.some((id) => datasetId === id)
}

export function addDatasetToRequested(datasetId: string) {
  const subscriptionsRequested: string[] = getLocalStorageItem(REQUEST_SUBS) || []
  subscriptionsRequested.push(datasetId)

  setLocalStorageItem(REQUEST_SUBS, subscriptionsRequested)
}

export function removeDatasetFromRequested(datasetId: string) {
  const subscriptionsRequested: string[] = getLocalStorageItem(REQUEST_SUBS) || []
  const idx = subscriptionsRequested.findIndex((id) => datasetId === id)
  subscriptionsRequested.splice(idx, 1)

  setLocalStorageItem(REQUEST_SUBS, subscriptionsRequested)
}
