import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FeatureFlag, FeatureFlagOverrides } from 'app/types'
import { takeEvery } from 'redux-saga/effects'
import { BackendEvent } from '../api/backendEvents'
import { LDFlagChangeset } from 'launchdarkly-react-client-sdk'
import { RootState } from 'app/state/store'
import { RequestStatus } from '../types'

export interface CommonState {
  accessToken: string | undefined
  ldStatus: RequestStatus
  featureFlagsFromLd: FeatureFlagOverrides
  featureFlagOverrides?: FeatureFlagOverrides
}

export const backendEvent = createAction<BackendEvent>(`common/BackendEvent`)

const initialState: CommonState = { accessToken: undefined, featureFlagsFromLd: {}, ldStatus: RequestStatus.Idle }

const reducers = {
  setAccessToken: (state: CommonState, action: PayloadAction<string | undefined>) => {
    state.accessToken = action.payload
  },
  setFeatureFlagsFromLd: (state: CommonState, action: PayloadAction<FeatureFlagOverrides>) => {
    state.featureFlagsFromLd = action.payload
  },
  setLdStatus: (state: CommonState, action: PayloadAction<RequestStatus>) => {
    state.ldStatus = action.payload
  },
  updateFeatureFlagsFromLd: (state: CommonState, action: PayloadAction<LDFlagChangeset>) => {
    const flags = action.payload
    Object.entries(flags).forEach(([flagKey, flagValue]) => {
      state.featureFlagsFromLd[flagKey] = flagValue.current
    })
  },
  setFeatureFlagOverrides: (state: CommonState, action: PayloadAction<FeatureFlagOverrides>) => {
    state.featureFlagOverrides = action.payload
  }
}

export const launchDarklyFeatureFlagsReady = createSelector(
  (state: RootState) => state.common.ldStatus,
  (ldGoalsStatus) => ldGoalsStatus === RequestStatus.Fulfilled
)

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers
})

export const getFeatureFlag = (state: RootState, flag: FeatureFlag) => {
  return state.common.featureFlagOverrides?.[flag] ?? state.common.featureFlagsFromLd[flag]
}

export const { setAccessToken, updateFeatureFlagsFromLd, setLdStatus, setFeatureFlagsFromLd, setFeatureFlagOverrides } =
  commonSlice.actions

export default commonSlice.reducer

export function takeEveryBackendEvent(
  eventType: BackendEvent['type'],
  handler: (action: PayloadAction<BackendEvent>) => Generator
) {
  return takeEvery(backendEvent.type, function* (action: PayloadAction<BackendEvent>) {
    if (action.payload.type !== eventType) {
      return
    }
    return yield handler(action)
  })
}
