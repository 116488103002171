export enum FeatureFlag {
  CollaborativeMaps = 'frontend-2022-collaborative-maps',
  AtInstallation = 'frontend-2022-at-installation',
  BuilderTableToQuery = 'frontend-2022-builder-table-to-query',
  BasemapSelectorInLegend = 'frontend-2023-new-basemap-selector-in-builder',
  NPSVitallySurvey = '2024-nps-with-vitally',
  CustomAggregationInFormulaWidget = '2023-custom-aggregation-in-formula-widget',
  WorkflowsScheduling = '2023-workflows-scheduling',
  WorkflowsDO = '2023-workflows-do',
  SqlParamsNumeric = '2023-sql-params-numeric',
  WorkflowsExport = '2023-workflows-export',
  WorkflowsExport2 = '2023-workflows-export-ii',
  WorkflowsImport = '2023-workflows-import',
  WorkflowsEnrichment = '2023-workflows-enrichment',
  BuilderPieWidget = '2023-builder-pie-widget',
  WorkflowsGenAiDescription = '2023-workflows-gen-ai-description',
  BuilderHexCode = '2023-builder-hex-code',
  ServerSideMapFiltering = '2023-server-side-map-filtering',
  WorkflowsDataExport = '2023-workflows-data-export',
  RequestOnlyRequiredColumns = '2024-request-only-required-columns',
  CustomBasemaps = '2024-custom-basemaps',
  ExportPDF = '2024-pdf-reports-from-builder',
  DatabricksConnectionEnabled = '2024-databricks-connection-enabled',
  WorkflowsRasterComponents = '2024-workflows-raster-components',
  ReduceNumberOfQueries = '2024-reduce-number-of-queries',
  DeprecateDateForTilesetFromDataExplorer = '2024-deprecate-create-tileset-from-dataexplorer-ui',
  WorkflowsPackageExtension = '2024-workflows-package-extension',
  QueryConcurrencyControl = '2024-query-concurrency-control',
  HeatmapAggregationInBuilder = '2024-heatmap-aggregation-in-builder',
  ClusterAggregationInBuilder = '2024-point-cluster-aggregation-in-builder',
  ZoomToLayerInBuilder = '2024-zoom-to-layer-in-builder',
  PrivateBuckets = '2024-private-buckets',
  DatabricksRestConnectionEnabled = '2024-databricks-rest-connection-enabled',
  TagsForResources = '2024-tags-for-resources',
  CatalogWorkflowTemplates = '2024-catalog-workflow-templates',
  DeliverRawAuditLogs = '2024-deliver-raw-audit-logs',
  H3PointsAggregation = '2024-point-h3-aggregation-in-builder',
  SuperadminMode = '2024-superadmin-mode',
  TableWidgetRevamp = '2024-table-widget-revamp',
  TableWidgetHighlightAndZoom = '2024-table-widget-highlight-and-zoom',
  SpatialDataAndLayerRevamp = '2024-spatial-data-definition-and-layer-revamp',
  SnowflakeRowLevelSecurity = '2024-snowflake-viewer-creds-row-level-security',
  BuilderGenAI = '2024-builder-gen-ai',
  OrganizationPalettes = '2024-organization-palettes',
  ForceLoginMethodEnabled = '2024-force-login-method',
  NamedSources = '2024-sql-named-sources-ui'
}

export type FeatureFlagSet = Record<FeatureFlag, boolean | string>
export type FeatureFlagOverrides = Partial<FeatureFlagSet>

export type DeepPartial<K> = {
  [attr in keyof K]?: K[attr] extends object
    ? DeepPartial<K[attr]>
    : K[attr] extends object | null
    ? DeepPartial<K[attr]> | null
    : K[attr] extends object | null | undefined
    ? DeepPartial<K[attr]> | null | undefined
    : K[attr]
}
