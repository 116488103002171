import { SourceFilters } from '@carto/react-redux'
import { Map, MapType } from 'features/maps/types'
import { QueryParametersSimple } from 'features/sql/types'
import { Geometry } from 'geojson'
import { ImportFileType } from 'features/common/types'
import { Connection } from 'features/connections'
import { SpatialDataType } from 'features/builder/types'

export enum JobsPopperTab {
  Processing = 'processing',
  Imports = 'imports',
  Exports = 'exports'
}

export type Job = ProcessingJob | ImportingJob | ExportJob | LocalLayerDataExportJob | ActivityDataExportJob

/* eslint-disable @typescript-eslint/no-explicit-any */
export enum JobStatusType {
  Running = 'running',
  Pending = 'pending',
  Failure = 'failure',
  Cancelled = 'cancelled',
  Success = 'success'
}

export enum JobType {
  Processing = 'processing',
  Importing = 'importing',
  Workflow = 'workflow', // actually a processing job, with metadata.type === 'workflow'

  /**
   *  Frontend based export of dataset by specialized query actually a processing job.
   *
   *  Disinguished by `metadata.type === 'exporting'`
   */
  Exporting = 'exporting',

  /**
   * Actually not a backend job, but an entry to show for local layer data export.
   */
  LocalLayerDataExport = 'local-layer-data-export',
  DatasetExport = 'dataset-export',

  ActivityData = 'activity-data',
  WorkflowDataExport = 'workflow-data-export'
}

export enum ProcessingType {
  CreateTileset = 'tilesets',
  Geocode = 'geocode',
  EnrichTable = 'enrich',
  OptimizeTable = 'optimize',
  PrepareTable = 'prepare',
  ExportTable = 'export'
}

export interface ProcessingJobResponse {
  accountId: string
  connectionId: string
  createdAt: string | Date
  externalId: string
  metadata: any
  userId: string
}

export interface JobStatus {
  jobId: string
  status: JobStatusType
  createdAt?: string
  query?: string
  error?: { msg: string; code: string }
  provider?: any
}

export type JobBase = {
  type: JobType
  id: string // TODO: rename to localId so it's clear it's not the backend jobId
  name: string
  status: JobStatusType
  createdAt: number
  error?: { msg: string; code: string }
}

export interface ProcessingJob {
  jobId: string
  connection?: string
  name?: string
  response: ProcessingJobResponse
  status?: JobStatus
  dryRun?: any
  event?: {
    name: string
    payload: any
  }
}

export type DatasetExportEntry = {
  name: string

  connectionName: string
  source: string
  queryParameters?: QueryParametersSimple
}

export type ExportJobParams = {
  connectionName: string
  jobId: string
  accessToken?: string
}

export type ExportJobResponse = ExportJobParams & {
  name: string
}

export interface ExportJobBackendMetadata {
  sourceName: string
  expirationDate?: string
}

export interface ExportJobStatus extends JobStatus {
  backendMetadata: ExportJobBackendMetadata
  downloadUrls?: string[]
  errorMessage?: string
  downloadUrlsExpirationDate?: string
}

export type ActivityDataExportJobParams = {
  jobId: string
}

export interface ActivityDataExportJobStatus extends JobStatus {
  errorMessage?: string
  downloadUrls?: Record<string, string[]>
  downloadUrlsExpirationDate?: string
}

export type LocalLayerDataExportJob = JobBase & {
  type: JobType.LocalLayerDataExport
  params: {
    datasetId: string
    filters?: SourceFilters
  }
}

export type SpatialFilter = Record<string, Geometry>

export type DataExportRequest = {
  sources: DataExportSource[]
}

export type DataExportSource = {
  name: string

  connectionName: string
  type: MapType
  source: string
  queryParameters?: QueryParametersSimple
  filters?: SourceFilters
  spatialFilter?: SpatialFilter
  spatialDataType?: SpatialDataType
  spatialFiltersResolution?: number
  spatialDataOptions?: {
    column?: string
    type?: string
  }
  accessToken?: string
}

export type ExportJob = JobBase & {
  type: JobType.DatasetExport | JobType.WorkflowDataExport

  params: DataExportSource
  jobParams?: ExportJobParams
  remoteJobStatus?: ExportJobStatus
}

export type ActivityDataExportJob = JobBase & {
  type: JobType.ActivityData
  jobParams: {
    categories: string[]
    format: string
    startTime: string
    endTime: string
  }
  remoteJobStatus?: ActivityDataExportJobStatus
}

export type LayerExportJob = ExportJob | LocalLayerDataExportJob

export enum ExportDataMethod {
  Local = 'local',
  ServerExport = 'server-export'
}

export function isLayerExportJob(job: any): job is LayerExportJob {
  return (
    job &&
    (job.type === JobType.DatasetExport ||
      job.type === JobType.WorkflowDataExport ||
      job.type === JobType.LocalLayerDataExport)
  )
}

export function isDatasetExportingJob(job: any): job is ProcessingJob {
  return job?.response?.metadata?.type === JobType.Exporting
}

export function isActivityDataExportJob(job: any): job is ActivityDataExportJob {
  return job && job.type === JobType.ActivityData
}

export function isWorkflowDataExportJob(job: any): job is ActivityDataExportJob {
  return job && job.type === JobType.WorkflowDataExport
}

export interface ProcessingJobPayload {
  query: string
  queryParameters?: QueryParametersSimple | null
  metadata?: any
  tableId?: string
}

export interface ProcessingJobStatus extends JobStatus {
  query: string
}

export interface ProcessingJobStatusStatistics {
  endTime: string
  startTime: string
}

export interface ImportingJob {
  jobId: string
  connection: string
  destination: string
  createdAt?: string
  name: string
  status: JobStatusType
  error?: string | Record<string, never>
  mapId?: Map['id']
  metadata?: { workflows?: boolean }
}

export function isImportingJob(job: any): job is ImportingJob {
  // TODO: use type
  return job && typeof job.destination === 'string'
}

export function isProcessingJob(job: any): job is ProcessingJob {
  return (
    // TODO: use type
    job && typeof job === 'object' && typeof job.jobId === 'string' && !isImportingJob(job) && !isLayerExportJob(job)
  )
}

export type ColumnPreview = {
  name: string
  nullable: boolean
  type: string
}
export type SchemaPreview = {
  columns: ColumnPreview[]
  options: { name: string }[]
}

export type SchemaPreviewResponse = {
  url: URL
  preview: SchemaPreview
}

export type SchemaMap = Record<string, ColumnPreview>

export interface ImportingActionPayload {
  connection: string
  destination: string
  name: string
  file: string | File | URL
  createLayerAfterJobImportedForMap?: Map['id']
  autoguessing?: boolean
  schema?: SchemaMap
  metadata?: { workflows?: boolean }
}

export interface ImportingJobPayload {
  owner?: string
  createdSince: string
  status?: string
}

export interface ImportingJobResponse {
  jobId: string
  connection: string
  createdAt: string
  destination: string
  status: JobStatusType
  error: string
  metadata?: { workflows?: boolean }
}

export interface DryRunPayload {
  outputTable: string
  connection: Connection
  overwrite?: boolean
}

export { ImportFileType }

export type ImportFileTypeResponse = {
  file: File | URL
  fileType: ImportFileType
}

export enum ResponseStatus {
  Ok = 'ok'
}

export enum JobErrorCode {
  Stopped = 'stopped',
  Query = 'invalidQuery'
}

export const CARTO_IGNORED_PROCESSING_JOBS = 'CARTO_IGNORED_PROCESSING_JOBS'
export const CARTO_IGNORED_IMPORTING_JOBS = 'CARTO_IGNORED_IMPORTING_JOBS'
export const CARTO_IGNORED_EXPORTING_JOBS = 'CARTO_IGNORED_EXPORTING_JOBS'
export const CARTO_LOCAL_STORAGE_USER_ID = 'userId'
export const CARTO_EXPORT_TASKS = 'exportTasks'
