/* eslint-disable redux-saga/no-unhandled-errors */
import { ApiError, RequestStatus } from 'features/common'
import { useEffect } from 'react'
import { Action } from 'redux'
import { put, select, take } from 'redux-saga/effects'
import { useAppDispatch, useAppSelector } from '.'
import { RootState } from './store'

/**
 * Redux Toolkit Query compatible hook to use resources managed by
 *   - slice.name
 *   - slice.nameStatus
 *   - slice.nameError
 * triplet in redux store.
 *
 * Usage:
 *
 * 1. Define resource as set of
 *    - data selector
 *    - status selector
 *    - error selector
 *    - action (or factory) invoked it data is not yet loaded (idle)
 *
 *    ```
 *    export const statsResource = createResource({
 *      get: (state) => state.account.statsData?.stats,
 *      status: (state) => state.account.statsStatus,
 *      error: (state) => state.account.statsError,
 *      createAction: () => asyncActions.statsRequest()
 *    })
 *    ```
 *
 * 2. Use it in react component:
 *
 *    ```
 *    function Foo() {
 *      const { data: stats, isLoading; statsLoading } = useResource(statsResource)
 *    }
 *    ```
 * Supports actions that require parametes, i.e if `createFunction` accepts params:
 *
 * ```
 * export const userDef = createResource({
 *   //...
 *   createAction: (userId: string) => asyncActions.userRequest(userId)
 * })
 * ```
 * then it's required to pass those params to `useResource`: `useResource(userDef, 'param')`.
 *
 * @see https://redux-toolkit.js.org/rtk-query/overview
 */
export function useResource<T, F extends unknown[]>(def: ResourceDef<T, F>, ...args: F) {
  const dispatch = useAppDispatch()
  const data = useAppSelector((state) => def.get(state))
  const status = useAppSelector((state) => def.status(state))
  const error = useAppSelector((state) => def.error?.(state))

  useEffect(() => {
    if (status === RequestStatus.Idle) {
      dispatch(def.createAction(...args))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, def, status, ...args])

  return {
    data: data as T | undefined,
    isLoading: status === RequestStatus.Pending || (data === undefined && status === RequestStatus.Idle),
    error,
    status: status || RequestStatus.Idle
  }
}

export interface ResourceDef<T, F extends unknown[]> {
  get: (state: RootState) => T
  status: (state: RootState) => RequestStatus | undefined
  error?: (state: RootState) => ApiError | null | undefined
  createAction: (...args: F) => Action
}

/** Define resource for use with `useResource`
 *
 * As for now does nothing, it's just convenience function to enforce proper typing of resource
 * definition.
 */
export function createResource<T, F extends unknown[]>(def: ResourceDef<T, F>) {
  return def
}

/**
 * Redux-saga accessor for resources defined using `createResource`
 *
 * * Returns resource if already loaded
 * * Otherwise dispatched "initiate" actions and waits for result
 * * Prevents multiple dispatches.
 * * Throws if request failed
 *
 * Usage:
 *
 *     const stats: AccountStatsDetails = yield call(getResourceStateful, statsResource)
 */
export function* getResourceStateful<T, F extends unknown[]>(def: ResourceDef<T, F>, ...args: F) {
  let localPending = false
  while (true) {
    const { data, status }: { data: T | undefined; status?: unknown } = yield select((state) => ({
      data: def.get(state),
      status: def.status(state)
    }))
    switch (status) {
      case RequestStatus.Idle:
        if (!localPending) {
          yield put(def.createAction(...args))
          localPending = true
        }
        break

      case RequestStatus.Fulfilled:
        localPending = false
        return data as T

      case RequestStatus.Rejected: {
        localPending = false
        // TODO: if it was rejected from start, then maybe we can dismiss and start from beginning (?)
        const error = yield select((state) => def.error?.(state))
        if (error) {
          throw new Error(error.error)
        } else {
          // Given we usually don't update redux atomically, let's wait until
          // actual 'setFooError' is dispatched too ...
        }
      }
    }

    yield take() // TODO: really, we have to wait for every action?
  }
}
