import { QuotaResult } from 'features/quotas/types'
import { UseFormMethods } from 'react-hook-form'
import { CartoCatalogErrorCode } from './errors'

export enum RequestStatus {
  Idle = 'Idle',
  Pending = 'Pending',
  Fulfilled = 'Fulfilled',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled'
}

export enum ResourceType {
  Connection = 'connection',
  Map = 'map',
  Workflow = 'workflow',
  App = 'app'
}

export interface ApiError {
  fields?: { field: string; message: string }[]
  errorDetails?: string[]
  stack?: string
  status: number
  error: string
  quota?: QuotaResult
  details?: string
  originalMessage?: string
  clientMetadata?: Record<string, string> // To inject some metadata directly from frontend
}

export interface PaginatedResponse<T> {
  data: Array<T>
  links: {
    prev: string
    next: string
  }
  meta: {
    page: PageMetadata
  }
}

export interface PageMetadata {
  current: number
  total: number
  totalPages: number
  pageSize: number
}

// TODO: This should probably be managed with labels, not an enum
export enum FilteringTypes {
  LAST_UPDATED = 'last-updated',
  ALPHABETICAL = 'a-z'
}

export enum FieldToOrderBy {
  Title = 'title',
  Email = 'email',
  Recipient = 'recipient',
  UpdatedAt = 'updated_at',
  CreatedAt = 'created_at',
  RequestedAt = 'requestedAt',
  Views = 'views',
  Name = 'name',
  updatedAt = 'updatedAt'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export interface ListOrder {
  by: FieldToOrderBy
  direction: SortDirection
}

export interface UploadFileOptions {
  contentType?: string
  cacheControl?: string
  maxFileSizeUpload?: number
  filename?: string
}

export type NonSubmitableForm = Omit<UseFormMethods, 'handleSubmit'>
export type SimplifiedForm = Omit<UseFormMethods, 'handleSubmit' | 'formState'>

export interface DataLimits {
  maxRows?: number
  maxSize?: number
}

export enum CommonPrivacy {
  Private = 'private',
  Shared = 'shared',
  Public = 'public',
  SharedWithGroups = 'shared_with_groups'
}

export type FalsyValue = 0 | false | undefined | null | ''

export interface GcsSignedUrl {
  mode?: 'read' | 'write' // TODO: To be used by datasets, or to be removed
  url: string
  readUrl?: string
  headers: Record<string, string>
}

export enum SharingFeature {
  Connections = 'connections',
  Apps = 'apps'
}

export enum RoutePath {
  Edit = 'edit',
  Create = 'create'
}

export enum OrganizationSharingOptions {
  All = 'all',
  Groups = 'groups',
  ViewerCredentials = 'viewer_credentials'
}

export const lastReloadKey = 'last-reload'

export enum ImportFileType {
  Vector = 'vector',
  Raster = 'raster'
}

export interface AssetsResponse {
  id: string
  url: string
}

export enum DryRunResult {
  Ok,
  notFound,
  alreadyExists,
  willBeOverwritten,
  accessDenied,
  otherError
}

export const DryRunResultCatalog = {
  [CartoCatalogErrorCode.UnauthorizedTableLocation]: DryRunResult.accessDenied,
  [CartoCatalogErrorCode.TableAlreadyExists]: DryRunResult.alreadyExists,
  [CartoCatalogErrorCode.TableWillBeOverwritten]: DryRunResult.willBeOverwritten,
  [CartoCatalogErrorCode.LocationNotFound]: DryRunResult.notFound
}
